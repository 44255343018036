import React, {createContext, useState} from 'react';
import {  Paper } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DonationForm from './forms/DonationForm';
import NaadaBinduForm from './forms/NaadaBinduForm';
import ChykconForm from './forms/ChykconForm';
import ElementaryLockinForm from './forms/ElementaryLockinForm';
import HighSchoolLockinForm from './forms/HighSchoolLockinForm';
import MiddleSchoolLockinForm from './forms/MiddleSchoolLockinForm';
import BanquetForm from './forms/BanquetForm';
import ReportForm from './forms/ReportForm';
import EventTypesForm from './forms/EventTypesForm';

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));

export const DataContext = createContext();


function App() {

  const [events, setEvents] = useState([]);
    

  return (
    <div className="App">
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <DemoPaper variant="elevation">
        <DataContext.Provider value={ {events, setEvents}}>
          <Router>
            <Routes>
              <Route path="/" element={<EventTypesForm />} />
              <Route path="/DonationForm" element={<DonationForm />} />
              <Route path="/NaadaBinduForm" element={<NaadaBinduForm />} />
              <Route path="/ChykconForm" element={<ChykconForm />} />
              <Route path="/ElementaryLockinForm" element={<ElementaryLockinForm />} />
              <Route path="/MiddleSchoolLockinForm" element={<MiddleSchoolLockinForm />} />
              <Route path="/HighSchoolLockinForm" element={<HighSchoolLockinForm />} />
              <Route path="/Banquet" element={<BanquetForm />} />
              <Route path="/Report" element={<ReportForm />} />
            </Routes>
          </Router>
          </DataContext.Provider>
        </DemoPaper>
      </Container>
    </div>
  );
}

export default App;
